// colors
$primary-color: #8dcee0;
$primary-color-rgb: 141, 206, 224;
// $primary-color: #003865;
// $primary-color-rgb: 0, 56, 101;
$primary-color-contrast: #ffffff;
$primary-color-contrast-rgb: 255, 255, 255;

$secondary-color: #0c3d56;
$secondary-color-rgb: 12, 61, 86;
$secondary-color-contrast: #ffffff;
$secondary-color-contrast-rgb: 255, 255, 255;

$warning-color: #ffce00;
$warning-color-rgb: 255, 206, 0;
$warning-color-contrast: #ffffff;
$warning-color-contrast-rgb: 255, 255, 255;

$danger-color: #f04141;
$danger-color-rgb: 245, 61, 61;
$danger-color-contrast: #ffffff;
$danger-color-contrast-rgb: 255, 255, 255;

$dark-color: #404040;
$dark-color-rgb: 64, 64, 64;
$dark-color-contrast: #ffffff;
$dark-color-contrast-rgb: 255, 255, 255;

$medium-color: #808080;
$medium-color-rgb: 128, 128, 128;
$medium-color-contrast: #ffffff;
$medium-color-contrast-rgb: 255, 255, 255;

$light-color: #CCCCCC;
$light-color-rgb: 204, 204, 204;
$light-color-contrast: #000000;
$light-color-contrast-rgb: 0, 0, 0;

// fonts
$default-font: "Roboto", sans-serif;
$default-font-size: 16px;

// left menu
$default-logo-file: 'images/theme/logo.jpg';
$bg-menu-color: #525E6A;
$bg-menu-image: 'bg-menu.png';
$menu-color: $light-color; //#D3D6D9;
$menu-color-highlight: lighten($menu-color, 25%);
$color-memu-highlight: $primary-color;
$bg-overlay: $secondary-color; //#525E6A;
$bg-overlay-rgb: $secondary-color-rgb;

// dashboard
$bg-dashboard-color: #f39100;
$bg-dashboard-image: 'bg-dashboard.png';

// main content
$bg-content: #F8F8F8;
$bg-content-contrast: #FFFFFF;
$color-content: $dark-color;
$bg-content-logged-out: #FFFFFF;
$color-content-logged-out: $dark-color;

// global borders
$global-radius: 4px;
$global-padding: 16px;
$global-margin: 16px;

// inputs
$input-background: #FFFFFF;
$input-color: $dark-color;
$input-placeholder-color: #999999;
$input-border-color: #E7E7E7;//$light-color;
$input-height: 48px;

$form-width: 448px;


// buttons
$button-height: 48px;

:root {
    --theme-menu-opacity: linear-gradient(0deg, rgba(#{$bg-overlay-rgb},1) 0%, rgba(#{$bg-overlay-rgb},0.4) 20%, rgba(#{$bg-overlay-rgb},0.4) 85%, rgba(#{$bg-overlay-rgb},0.6) 100%);
    --ion-logo-menu: url('/assets/images/theme/logo.png') !important;
}

ion-menu ion-content .event-switch span {
    color: var(--ion-color-light) !important;
}

ion-menu ion-content .event-switch span svg-icon {
    fill: var(--ion-color-light) !important;
}

.header-overlay a {
    color: var(--ion-color-light) !important;
}